import React from 'react'
import './preloader.css';

function Delegates() {
    return (
        <div className="preloader-container">
          <div className="spinner"></div>
        </div>
    );
}

  // Send to Register Function

  // const sendToDirectorate = async (mssnID = null) => {
  //   const token = localStorage.getItem('token');
  //   try {
  //     const res = await axios({
  //       method: 'POST',
  //       url: `https://demo-api.mssnlagos.net/api/v1/sendtodirectorate?admitted[]=${mssnID ?? selected}`,
  //       headers: {
  //         'Content-type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     setUsers(() => res.data.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   console.log(mssnID);
  //   console.log(selected);
  // };

  // const sendToRegistry = async (mssnID = null) => {
  //   setSelected((prevState) => {
  //     return [...prevState, mssnID];
  //   });

  //   const token = localStorage.getItem('token');
  //   const toastId = toast.loading('Sending...');
  //   try {
  //     const res = await axios({
  //       method: 'POST',
  //       url: `https://demo-api.mssnlagos.net/api/v1/sendtoregistry?admitted[]=${selected}`,
  //       headers: {
  //         'Content-type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     toast.remove(toastId);
  //     toast.success(res.data.message, {
  //       id: 'clipboard'
  //     });
  //   } catch (error) {
  //     toast.remove(toastId);
  //     toast.error(error.response.data.message)
  //   }
    
  //   console.log(mssnID);
  //   console.log(selected);
  // };

  // const refresh = () => window.location.reload(true);

export default Delegates