import axios from 'axios';
const base_URL = "https://demo-api.mssnlagos.net/api/"


export const GETwithTOKEN = async (URI) => {
    try {
        const token = localStorage.getItem("token")
        const res =  await axios({
            method: "GET",
            URL: `${base_URL}${URI}`,
            headers: {
                "accept" : "application/json",
                "Content-Type" : "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error.response
    }
}

export const POSTwithTOKEN = async (URI, data) => {
    try {
        const token = localStorage.getItem('token')
        const res = await axios ({
            method: "POST",
            URL: `${base_URL}${URI}`,
            data,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error.response
    }
}

export const POSTwithoutTOKEN = async (URI, data) => {

    try {
      const res = await axios.post(base_URL + URI, data)
      return res;
    } catch (error) {
      return error.response
    }
  
  }