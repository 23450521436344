import { Routes, Route } from "react-router-dom";
import './App.css';
import {Toaster} from 'react-hot-toast'
import PageLayout from './shared_components/PageLayout.jsx';
import SignIn from './Auth/SignIn'
import SignUp from './Auth/SignUp'

function App() {
  return (

    <>
      <Routes>
          <Route  path="/" element={<SignIn />}/>
          <Route path="/PageLayout" element={<PageLayout/>}/>
          <Route path="/SignUp"  element = {<SignUp/>} />
      </Routes>
      <Toaster/>
    </>
  );
}

export default App;
